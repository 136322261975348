import React from "react";
import ReactDOM from "react-dom";
import "./Assets/Css/index.css";
import "./Assets/Css/App.css";
import "./Fonts/Noe/Noe Text Semibold.otf";
import "./Fonts/Noe/Noe Text Bold.otf";
import "./Fonts/Noe/Noe Text Regular.otf";
import "./Fonts/Theinhardt/Theinhardt-Regular.otf";
import "./Fonts/Theinhardt/Theinhardt-Medium.otf";
import "./Fonts/Theinhardt/Theinhardt-Bold.otf";
import "./Fonts/horizon/Arch_Reg.otf";
import "./Fonts/horizon/Arch_Reg_Ital.otf";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// Horizon Type - AcherusGrotesque-Regular.otf
