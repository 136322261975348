import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import Remove from "../Icons/Remove";
import localforage from "localforage";

const SlideoutProduct = ({ product, setCart, cart, amount }) => {
  const removeProduct = () => {
    const removeIndex = cart.indexOf(product);
    const copyCart = JSON.parse(JSON.stringify(cart));
    copyCart.splice(removeIndex, 1);
    setCart(copyCart);
    localforage.setItem("cart", copyCart);
  };

  const handleAmountChange = (e) => {
    let updatedCart = cart.map((item) =>
      item.id === product.id && product.secondTitle === item.secondTitle
        ? { ...item, quantity: parseInt(e.target.value) }
        : item
    );
    setCart(updatedCart);
    localforage.setItem("cart", updatedCart);
    e.preventDefault();
  };

  return (
    <div>
      <div className="slideout-product-wrapper">
        <div className="slideout-products-left">
          <img className="slide-products-image" src={product?.img?.url} alt="" />
        </div>
        <div className="slideout-products-center">
          <div className="slideout-product-title">
            <h2>{product.name}</h2>
            <p className="item-subscription">
              {product?.purchaseType==='subscribe'
                ? `Subscription `
                : `One Time Purchase `}
            </p>
          </div>
          <div className="quantity-price">
            <div className="slideout-products quantity-selector" style={{ display: "flex", position: "relative" }}>
              <div>
                <select
                  className="slideout-dropdown"
                  value={`Quantity: ${amount}`}
                  onChange={(e) => handleAmountChange(e)}
                >
                  <option>Quantity: {amount}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <button onClick={() => removeProduct()} className="slideout-product-button">
                <Remove /> <span className="remove-span">Remove</span>
              </button>
            </div>{" "}
            <div className="slideout-products-right">
              <div className="slideout-products-right-price">
                <span className="price-sign">$</span> {product.price}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideoutProduct;
